var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card-form",
    {
      attrs: { loading: _vm.loading },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              !_vm.disabled
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-success px-5",
                      attrs: { type: "button" },
                      on: { click: _vm.submeter }
                    },
                    [_vm._v(_vm._s(_vm.model.id == 0 ? "Criar" : "Alterar"))]
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "btn btn-info px-5",
                  attrs: { type: "button" },
                  on: {
                    click: function() {
                      _vm.$router.push({ name: "sistema.usuario.lista" })
                    }
                  }
                },
                [_vm._v("Voltar")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-2 text-center" }, [
          _c("p", { staticClass: "m-0" }, [_vm._v("Edição de usuário")]),
          _c("i", {
            staticClass: "uil uil-user-circle",
            staticStyle: { "font-size": "5rem" }
          })
        ]),
        _c("div", { staticClass: "col-md-10" }, [
          _c("p", [_vm._v("Este usuário é um(a)?")]),
          _c(
            "div",
            {
              staticClass: "mb-3 d-flex flex-row",
              staticStyle: { gap: "1rem" }
            },
            _vm._l(_vm.atribuicoes, function(atribuicao) {
              return _c("div", { key: atribuicao.id }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.atribuicaoSelecionada,
                      expression: "atribuicaoSelecionada"
                    }
                  ],
                  staticClass: "form-check-input me-1",
                  attrs: {
                    type: "radio",
                    disabled: _vm.disabled,
                    id: "atribuicao-" + atribuicao.id
                  },
                  domProps: {
                    value: atribuicao,
                    checked: _vm._q(_vm.atribuicaoSelecionada, atribuicao)
                  },
                  on: {
                    change: function($event) {
                      _vm.atribuicaoSelecionada = atribuicao
                    }
                  }
                }),
                _c(
                  "label",
                  {
                    staticClass: "form-check-label",
                    attrs: { for: "atribuicao-" + atribuicao.id }
                  },
                  [_vm._v(" " + _vm._s(atribuicao.nome) + " ")]
                )
              ])
            }),
            0
          ),
          _c("p", [_vm._v("Representa?")]),
          _c(
            "div",
            { staticClass: "d-flex flex-row", staticStyle: { gap: "1rem" } },
            _vm._l(_vm.representacoes, function(representacao) {
              return _c("div", { key: representacao.id }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model.representacaoId,
                      expression: "model.representacaoId"
                    }
                  ],
                  staticClass: "form-check-input me-1",
                  attrs: {
                    type: "radio",
                    disabled: _vm.disabled,
                    id: "representacao-" + representacao.id
                  },
                  domProps: {
                    value: representacao.id,
                    checked: _vm._q(_vm.model.representacaoId, representacao.id)
                  },
                  on: {
                    click: function($event) {
                      return _vm.changeRepresentacao()
                    },
                    change: function($event) {
                      return _vm.$set(
                        _vm.model,
                        "representacaoId",
                        representacao.id
                      )
                    }
                  }
                }),
                _c(
                  "label",
                  {
                    staticClass: "form-check-label",
                    attrs: { for: "representacao-" + representacao.id }
                  },
                  [_vm._v(" " + _vm._s(representacao.nome) + " ")]
                )
              ])
            }),
            0
          )
        ])
      ]),
      _c("div", { staticClass: "separador my-4" }),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("f-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: { required: true },
                expression: "{ required: true }"
              }
            ],
            attrs: {
              name: "nome",
              disabled: _vm.disabled,
              "data-vv-as": "Nome",
              label: "Nome",
              modelErrors: _vm.errors,
              cols: 3
            },
            model: {
              value: _vm.model.nome,
              callback: function($$v) {
                _vm.$set(_vm.model, "nome", $$v)
              },
              expression: "model.nome"
            }
          }),
          _c("f-switch", {
            attrs: {
              name: "status",
              disabled: _vm.disabled,
              cols: 1,
              label: _vm.model.status ? "Ativo" : "Inativo",
              legend: "Status"
            },
            model: {
              value: _vm.model.status,
              callback: function($$v) {
                _vm.$set(_vm.model, "status", $$v)
              },
              expression: "model.status"
            }
          }),
          _c("f-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: { required: true, email: true },
                expression: "{ required: true, email: true }"
              }
            ],
            attrs: {
              name: "email",
              disabled: _vm.disabled,
              "data-vv-as": "E-mail",
              label: "E-mail",
              modelErrors: _vm.errors,
              cols: 5
            },
            model: {
              value: _vm.model.email,
              callback: function($$v) {
                _vm.$set(_vm.model, "email", $$v)
              },
              expression: "model.email"
            }
          }),
          _c("f-input", {
            attrs: {
              name: "dataAdd",
              disabled: true,
              value: _vm.toStringDatetime(_vm.model.dataAdd),
              label: "Criado em",
              cols: 3
            }
          }),
          _c("f-input", {
            directives: [
              {
                name: "mask",
                rawName: "v-mask",
                value: "###.###.###-##",
                expression: "'###.###.###-##'"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: { required: true, cpf: true },
                expression: "{ required: true, cpf: true }"
              }
            ],
            attrs: {
              name: "cpf",
              disabled: _vm.disabled,
              "data-vv-as": "CPF",
              label: "CPF",
              modelErrors: _vm.errors,
              cols: 3
            },
            model: {
              value: _vm.model.cpf,
              callback: function($$v) {
                _vm.$set(_vm.model, "cpf", $$v)
              },
              expression: "model.cpf"
            }
          }),
          _vm.atribuicaoSelecionada.camposObrigatorios.includes("crm")
            ? _c("f-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: { required: true },
                    expression: "{ required: true }"
                  }
                ],
                attrs: {
                  name: "crm",
                  disabled: _vm.disabled,
                  "data-vv-as": "CRM",
                  label: "CRM",
                  modelErrors: _vm.errors,
                  cols: 3
                },
                model: {
                  value: _vm.model.crm,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "crm", $$v)
                  },
                  expression: "model.crm"
                }
              })
            : _vm._e(),
          _vm.atribuicaoSelecionada.camposObrigatorios.includes("coren")
            ? _c("f-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: { required: true },
                    expression: "{ required: true }"
                  }
                ],
                attrs: {
                  name: "coren",
                  disabled: _vm.disabled,
                  "data-vv-as": "COREN",
                  label: "COREN",
                  modelErrors: _vm.errors,
                  cols: 3
                },
                model: {
                  value: _vm.model.coren,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "coren", $$v)
                  },
                  expression: "model.coren"
                }
              })
            : _vm._e(),
          _vm.atribuicaoSelecionada.camposObrigatorios.length == 0
            ? _c("div", { staticClass: "col-md-3" })
            : _vm._e(),
          _c("f-input", {
            directives: [
              {
                name: "mask",
                rawName: "v-mask",
                value: "(##) #####-####",
                expression: "'(##) #####-####'"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: { required: true },
                expression: "{ required: true }"
              }
            ],
            attrs: {
              name: "celular",
              disabled: _vm.disabled,
              "data-vv-as": "Celular",
              label: "Celular",
              modelErrors: _vm.errors,
              cols: 3
            },
            model: {
              value: _vm.model.celular,
              callback: function($$v) {
                _vm.$set(_vm.model, "celular", $$v)
              },
              expression: "model.celular"
            }
          }),
          _c("f-input", {
            attrs: {
              name: "ultimoLogin",
              disabled: true,
              value: _vm.toStringDatetime(_vm.model.ultimoLogin),
              label: "Último login",
              cols: 3
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "separador my-4" }),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("div", { staticClass: "col-md-1 d-flex align-items-center" }, [
            _c("label", { staticClass: "m-0" }, [_vm._v("Perfis")])
          ]),
          _c("f-selectsearch", {
            ref: "perfis",
            attrs: {
              name: "perfis",
              disabled: _vm.disabled,
              placeholder: "Digite para pesquisar o perfil",
              searchFunction: _vm.pesquisarPerfil,
              multiple: true,
              cols: 11
            },
            on: { select: _vm.selecionarPerfil },
            model: {
              value: _vm.model.perfis,
              callback: function($$v) {
                _vm.$set(_vm.model, "perfis", $$v)
              },
              expression: "model.perfis"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "separador my-4" }),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("div", { staticClass: "col-md-1 d-flex align-items-center" }, [
            _c("label", { staticClass: "m-0" }, [_vm._v("Convênios")])
          ]),
          _c("f-selectsearch", {
            ref: "convenios",
            attrs: {
              name: "convenios",
              disabled: _vm.disabled,
              placeholder: "Digite para pesquisar o convênio",
              searchFunction: _vm.pesquisarConvenio,
              multiple: true,
              cols: 11
            },
            on: { select: _vm.selecionarConvenio },
            model: {
              value: _vm.model.convenios,
              callback: function($$v) {
                _vm.$set(_vm.model, "convenios", $$v)
              },
              expression: "model.convenios"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "separador my-4" }),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("div", { staticClass: "col-md-1 d-flex align-items-center" }, [
            _c("label", { staticClass: "m-0" }, [_vm._v("Hospitais")])
          ]),
          _c("f-selectsearch", {
            ref: "hospitais",
            attrs: {
              name: "hospitais",
              disabled: _vm.disabled,
              placeholder: "Digite para pesquisar o hospital",
              searchFunction: _vm.pesquisarHospital,
              multiple: true,
              cols: 11
            },
            on: { select: _vm.selecionarHospital },
            model: {
              value: _vm.model.hospitais,
              callback: function($$v) {
                _vm.$set(_vm.model, "hospitais", $$v)
              },
              expression: "model.hospitais"
            }
          })
        ],
        1
      ),
      _c("div", [
        _c("div", { staticClass: "separador my-4" }),
        _c("div", { staticClass: "row" }, [
          _c("label", [_vm._v("Assinatura")]),
          _c("div", [
            _vm.assinatura != null
              ? _c("img", {
                  staticClass: "mt-2 mb-4 mr-3 me-4",
                  staticStyle: {
                    "max-width": "240px",
                    "max-height": "120px",
                    width: "auto"
                  },
                  attrs: { src: _vm.assinatura }
                })
              : _vm._e(),
            _vm.hideUpload
              ? _c(
                  "span",
                  {
                    on: {
                      click: function() {
                        _vm.hideUpload = false
                        _vm.assinatura = null
                      }
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "fas fa-sync-alt fa-3x c-pointer",
                      attrs: { title: "Alterar assinatura" }
                    })
                  ]
                )
              : _vm._e()
          ]),
          !_vm.disabled && !_vm.hideUpload
            ? _c("input", {
                ref: "file",
                attrs: { accept: "image/*", type: "file" },
                on: { change: _vm.uploadFile }
              })
            : _vm._e()
        ])
      ]),
      !_vm.disabled && _vm.model.id == 0
        ? _c("div", [
            _c("div", { staticClass: "separador my-4" }),
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("f-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: { required: _vm.model.id == 0 },
                      expression: "{ required: model.id == 0 }"
                    }
                  ],
                  attrs: {
                    name: "novaSenha",
                    type: "password",
                    "data-vv-as": "Senha do usuário",
                    label:
                      _vm.model.id > 0
                        ? "Alterar senha do usuário"
                        : "Senha do usuário",
                    modelErrors: _vm.errors,
                    cols: 3
                  },
                  model: {
                    value: _vm.model.novaSenha,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "novaSenha", $$v)
                    },
                    expression: "model.novaSenha"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      !_vm.disabled && _vm.model.id > 0
        ? _c("div", [
            _c("div", { staticClass: "separador my-4" }),
            _c(
              "button",
              {
                staticClass: "btn btn-warning px-5",
                attrs: { type: "button" },
                on: {
                  click: function() {
                    _vm.exibirSenha = true
                  }
                }
              },
              [_vm._v("Alterar senha do usuário")]
            )
          ])
        : _vm._e(),
      _vm.model.id > 0 && !_vm.disabled
        ? _c(
            "b-modal",
            {
              ref: "modal-senha",
              attrs: {
                title: "Alterar senha",
                hideHeaderClose: true,
                centered: true
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "modal-footer",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "w-100" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "me-2",
                                attrs: { variant: "info", size: "sm" },
                                on: {
                                  click: function() {
                                    _vm.exibirSenha = false
                                  }
                                }
                              },
                              [_vm._v(" Cancelar ")]
                            ),
                            _c(
                              "b-button",
                              {
                                staticClass: "me-2",
                                attrs: { variant: "success", size: "sm" },
                                on: {
                                  click: function($event) {
                                    return _vm.confirmAlterarSenha()
                                  }
                                }
                              },
                              [_vm._v(" Alterar ")]
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1001219800
              ),
              model: {
                value: _vm.exibirSenha,
                callback: function($$v) {
                  _vm.exibirSenha = $$v
                },
                expression: "exibirSenha"
              }
            },
            [
              _c(
                "div",
                { staticClass: "filtro-inline" },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Nova Senha:" } },
                    [
                      _c("b-input", {
                        attrs: { type: "password" },
                        model: {
                          value: _vm.senha,
                          callback: function($$v) {
                            _vm.senha = $$v
                          },
                          expression: "senha"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    { attrs: { label: "Confirmar Senha:" } },
                    [
                      _c("b-input", {
                        attrs: { type: "password" },
                        model: {
                          value: _vm.confirmarSenha,
                          callback: function($$v) {
                            _vm.confirmarSenha = $$v
                          },
                          expression: "confirmarSenha"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }